@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");

.app {
  margin-top: 56px;
}

.border-world {
  border-left: none;
}
@media (min-width: 993px) {
  .card-height {
    height: 200px;
  }
  .border-world {
    border-left: 1px solid rgb(165, 165, 165);
  }
  #main-nav {
    height: 50px;
  }
  .app {
    margin-top: 50px;
  }
}
.ursula {
  color: white;
  font-size: x-large;
}
.title {
  font-family: "Oswald", sans-serif;
  font-size: x-large;
}

.nav-link {
  font-family: "Oswald", sans-serif;
  color: #00d6d6 !important;
  font-size: larger;
}
.nav-title {
  font-family: "Oswald", sans-serif;
  color: #fafcfc !important;
  font-size: x-large;
}
.contact-title {
  font-family: "Oswald", sans-serif;
  color: #00d6d6 !important;
  font-size: x-large;
}
.contact {
  font-family: "Oswald", sans-serif;
  color: #fafcfc !important;
  font-size: large;
}
.nav-link:hover {
  color: white !important;
}
.read-more {
  color: #002a32 !important;
  font-family: cursive;
  font-weight: bold;
  padding-left: 1px;
  font-size: normal;
}
.read-more:hover {
  color: #011616 !important;
  border-bottom: 2px solid #011616;
}
.logo {
  max-height: 100px;
}
.nav-bg {
  background-color: #002a32 !important;
}
.part {
  max-width: 350px;
}

.part {
  font-family: "";
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 250;
  display: flex;
  background-color: #1d7c72;
  align-items: center;
  vertical-align: middle;
  font-size: large;
  width: 199vh;
  font-family: "normal";
}
.part-2 {
  min-height: 600px;
  background-color: #04272e;
  font-family: "Oswald", "sans-serif";
  font-weight: 350;
  display: flex;
  align-items: center;
  vertical-align: middle;
  font-size: larger;
  color: rgb(10, 10, 10);
}

.cardImage {

  width: 100%;
  height: 250px;
  object-fit: cover;
  border: 1px solid rgb(218, 226, 226)
}
.news {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  position: relative;
}
.part-3 {
  min-height: 600px;
  background-color: #c1cf9b;
  font-family: "Oswald", "sans-serif";
  font-weight: 850;
  display: flex;
  align-items: center;
  vertical-align: middle;
  font-size: x-large;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.9)
    ),
    url("./img/img3.jpg");
  color: rgb(250, 248, 248);
}

.quote {
  font-family: fantasy !important;
  font-size: large !important;
}

.footer-link {
  color: #00d6d6;
  text-decoration: underline;
}

.footer-link:hover {
  color: white;
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: "Georgia", serif;
  font-size: 16px;
  width: auto;
  min-height: 100vh;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: rgb(77, 76, 76);
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 16px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: rgb(27, 26, 26);
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

a.social {
  margin: 0 1rem;
  display: inline-block;
  align-self: end;
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #1660ff !important;
}

a.twitter {
  color: #60b3f7;
}

a.instagram {
  color: black;
}
a.youtube:hover {
  color: #e94839;
}

a.facebook:hover {
  color: #2f68e4;
}

a.twitter:hover {
  color: #69b4f1;
}

a.instagram:hover {
  color: rgb(26, 25, 25);
}

.img-text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.75);
  padding: 8px 8px;
  color: white;
  margin: 0;
  font: 1.1rem Sans-Serif;
}